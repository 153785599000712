<template>
  <div class="slide-in flex flex-col w-full">
    <div class="flex w-full space-x-16">
      <div class="flex-none">
        <image-uploader :data="{ photo: enrollee.photo }" :initials="enrolleeInitials"/>
      </div>
      <div class="space-y-8 flex-auto pb-16">
        <div class="shadow rounded-lg bg-white">
          <div class="px-4 py-6 flex justify-between p-x-4 py-4 items-center">
            <div class="text-header-color font-semibold">Enrollee Details</div>
            <div>
              <button @click="goBack">
                <span><em class="fas fa-times"></em></span>
              </button>
            </div>
          </div>
          <div class="border-t border-gray-100" />
          <form>
            <div class="overflow-hidden">
              <div class="px-4 py-5">
                <div class="grid grid-flow-row grid-cols-3 grid-rows-3 gap-6">
                 
                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="first-name"
                      >First name</label
                    >
                    <input
                      id="first-name"
                      v-model="enrollee.firstname"
                      autocomplete="given-name"
                      disabled
                      class="input-design"
                      name="first-name"
                      type="text"
                    />
                  </div>

                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="middle-name"
                      >Middle name</label
                    >
                    <input
                      id="middle-name"
                      v-model="enrollee.middle_name"
                      autocomplete="given-name"
                      disabled
                      class="input-design"
                      name="middle-name"
                      type="text"
                    />
                  </div>

                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="last-name"
                      >Last name</label
                    >
                    <input
                      id="last-name"
                      v-model="enrollee.lastname"
                      autocomplete="family-name"
                      disabled
                      class="input-design"
                      name="last-name"
                      type="text"
                    />
                  </div>

                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="insurance-number"
                      >Insurance Number</label
                    >
                    <input
                      id="insurance-number"
                      v-model="enrollee.insurance_no"
                      autocomplete="email"
                      disabled
                      class="input-design"
                      name="insurance-number"
                      type="text"
                    />
                  </div>
                  
                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="type"
                      >Type</label
                    >
                    <select
                      id="type"
                      v-model="enrollee.type"
                      autocomplete="country"
                      class="input-design"
                      disabled
                      name="type"
                    >
                      <option value="P">Principal</option>
                      <option value="D">Dependant</option>
                    </select>
                  </div>

                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="gender"
                      >Gender</label
                    >
                    <select
                      id="gender"
                      v-model="enrollee.sex"
                      autocomplete="country"
                      class="input-design"
                      disabled
                      name="gender"
                    >
                      <option value=""></option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option>Prefer not to identify</option>
                    </select>
                  </div>

                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="dob"
                      >Date of birth</label
                    >
                    <input
                      id="dob"
                      disabled
                      v-model="enrollee.birthdate"
                      class="input-design"
                      name="dob"
                      type="date"
                    />
                  </div>

                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="phone-number"
                      >Phone Number</label
                    >
                    <input
                      id="phone-number"
                      disabled
                      v-model="enrollee.phone"
                      class="input-design"
                      name="phone-number"
                      type="text"
                    />
                  </div>

                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="email"
                      >Email</label
                    >
                    <input
                      id="email"
                      v-model="enrollee.email"
                      disabled
                      class="input-design"
                      name="email"
                      type="email"
                    />
                  </div>

                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="status"
                      >Status</label
                    >
                    <select
                      id="status"
                      v-model="enrollee.status"
                      autocomplete="country"
                      class="input-design"
                      disabled
                      name="status"
                    >
                      <option value="AA">ACTIVE</option>
                      <option value="IA">IN - ACTIVE</option>
                      <option value="SX">SUSPENDED</option>
                      <option value="XX">TERMINATED</option>
                      <option value="PP">PENDED</option>
                      <option value="WP">WAITING PERIOD</option>
                      <option value="E">E</option>
                      <option value="EX">EX</option>
                    </select>
                  </div>
                  <div class="">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="staff_number"
                      >Staff Number</label
                    >
                    <input
                      id="staff_number"
                      disabled
                      v-model="enrollee.staff_number"
                      class="input-design"
                      name="staff_number"
                      type="text"
                    />
                  </div>
                  <div class="col-span-3">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="address"
                      >Address</label
                    >
                    <input
                      id="address"
                      disabled
                      v-model="enrollee.address"
                      class="input-design"
                      name="address"
                      type="text"
                    />
                  </div>
                  <div class="col-span-1">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="state"
                      >State</label
                    >
                    <input
                      id="state"
                      disabled
                      v-model="enrollee.state"
                      class="input-design"
                      name="state"
                      type="text"
                    />
                  </div>
                  <div class="col-span-2">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="lga"
                      >Local Government Area</label
                    >
                    <input
                      id="lga"
                      disabled
                      v-model="enrollee.lga"
                      class="input-design"
                      name="lga"
                      type="text"
                    />
                  </div>
                  <div class="col-span-1">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="bloodgroup"
                      >Blood Group</label
                    >
                    <input
                      id="bloodgroup"
                      disabled
                      v-model="enrollee.blood_group"
                      class="input-design"
                      name="bloodgroup"
                      type="text"
                    />
                  </div>
                  <div class="col-span-1">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="genotype"
                      >Genotype</label
                    >
                    <input
                      id="genotype"
                      disabled
                      v-model="enrollee.genotype"
                      class="input-design"
                      name="genotype"
                      type="text"
                    />
                  </div>
                  <div class="col-span-1">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="surgical_history"
                      >Surgical History</label
                    >
                    <input
                      id="surgical_history"
                      disabled
                      v-model="enrollee.surgical_history"
                      class="input-design"
                      name="surgical_history"
                      type="text"
                    />
                  </div>
                  <div class="col-span-3">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="allergies"
                      >Allergies</label
                    >
                    <input
                      id="allergies"
                      disabled
                      v-model="enrollee.allergies"
                      class="input-design"
                      name="allergies"
                      type="text"
                    />
                  </div>
                  <div class="col-span-3">
                    <label
                      class="block text-sm font-medium text-gray-700"
                      for="others"
                      >Other Informations</label
                    >
                    <input
                      id="others"
                      disabled
                      v-model="enrollee.others"
                      class="input-design"
                      name="others"
                      type="text"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="grid grid-flow-row grid-cols-2 gap-4 w-full">
          <div
            class="
              shadow
              rounded-lg
              bg-white
              px-4
              w-full
              py-3
              space-y-3
              col-span-2
            "
          >
            <div class="flex justify-between flex-col border-b-2">
              <span>Medical History</span>
            </div>
            <div
              class="mt-4 mb-4 flex flex-col"
              v-for="history in enrollee.medical_history"
              :key="history"
            >
              <div class="">
                <span class="block mute">name</span>
                <span class="e-details">{{ history.name }}</span>
              </div>
              <div class="grid grid-cols-2 gap-4">
                <div class="">
                  <span class="block mute">start date</span>
                  <span class="e-details">{{ history.start }}</span>
                </div>
                <div class="">
                  <span class="block mute">end date</span>
                  <span class="e-details">{{ history.end }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="enrollee.id && !enrollee.parent_id">
          <dependant :enrollee="enrollee" />
        </div>
        <div
          v-if="enrollee.dependants"
          class="grid grid-flow-row grid-cols-2 gap-4"
        >
          <div
            class="shadow rounded-lg bg-white px-4 py-3 space-y-3"
            v-for="(dependant, index) in enrollee.dependants"
            :key="index">
            <div class="flex justify-between">
              <span>Dependant Enrollee</span>
              <span
                class="cursor-pointer"
                @click="requestEnrolleeTermination({ id: dependant.id })"
                ><em class="fas fa-trash"></em
              ></span>
            </div>
            <div class="border-t border-gray-100" />
            <div
              class="grid grid-flow-row grid-cols-3 grid-rows-3 gap-y-4 gap-x-3"
            >
              <div class="">
                <span class="block mute">surname</span>
                <span class="e-details">{{ dependant.lastname }}</span>
              </div>
              <div class="">
                <span class="block mute">firstname</span>
                <span class="e-details">{{ dependant.firstname }}</span>
              </div>
              <div class="">
                <span class="block mute">relationship</span>
                <span class="e-details">{{
                  dependant.parent_relationship
                }}</span>
              </div>
              <div class="">
                <span class="block mute">Gender</span>
                <span class="e-details">{{ dependant.sex }}</span>
              </div>
              <div class="col-span-2">
                <span class="block mute">Date Of Birth</span>
                <span class="e-details">{{
                  formatDate(dependant.birthdate)
                }}</span>
              </div>
              <div class="">
                <span class="block mute">Phone Number</span>
                <span class="e-details">{{ dependant.phone }}</span>
              </div>
              <div class="">
                <span class="block mute">Email Address</span>
                <span class="e-details">{{ dependant.email }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 py-3 bg-white rounded-lg shadow space-y-4">
          <div class="flex">
            <div class="w-full">
              <div class="">
                <label
                  class="block text-sm font-medium text-gray-700"
                  for="plan"
                  >Plan</label
                >
              </div>
              <div class="justify-center items-center flex flex space-x-4">
                <div class="w-3/4">
                  <select
                    id="plan"
                    v-model="enrollee.hmo_plan_id"
                    class="input-design"
                    name="plan"
                  >
                    <option v-for="plan in plansArray" :key="plan.id" :value="plan.id">
                      {{ plan.name }}
                    </option>
                  </select>
                </div>
                <div class="w-1/4">
                  <button
                    class="block w-full change-button"
                    :disabled="!enrollee.id"
                    @click="requestPlanChange({ id: enrollee.id })"
                  >
                    Request Change
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="flex">
            <div class="w-full">
              <div class="">
                <label class="block text-sm font-medium text-gray-700"
                  >Provider</label
                >
              </div>
              <div class="justify-center items-center flex flex space-x-4">
                <div class="w-3/4">
                  <!-- TODO: Move to a shared component asap. I can't as I'm tired at the MOC -->
                  <VueMultiselect
                    :options="providerOptions"
                    label="name"
                    placeholder="Search Provider"
                    v-model="selected_provider"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :hide-selected="true"
                    :options-limit="10"
                    :loading="selectIsLoading"
                    :searchable="true"
                    track-by="id"
                    open-direction="bottom"
                    @search-change="searchProviders"
                  >
                  </VueMultiselect>
                </div>
                <div class="w-1/4">
                  <button
                    class="block w-full change-button"
                    @click="submitProviderChangeRequest"
                  >
                    Request Change
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="delete-button"
          :disabled="!enrollee.id"
          type="submit"
          @click="requestEnrolleeTermination({ id: enrollee.id })"
        >
          Terminate Account
        </button>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { getInitials } from "@/shared/helpers";
import { Axios } from "@/shared/axios";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import ImageUploader from "@/shared/ui/image-uploader";
import moment from "moment";
import { SuccessToast, ErrorToast } from "@/shared/mixins/Toast";
import Dependant from "@/app/enrollee/component/dependant";
import PlanService from "../../../services/plan/plan-service";

export default {
  name: "active-enrollee-details",
  components: { Dependant, ImageUploader, VueMultiselect },
  mixins: [SuccessToast, ErrorToast],
  data() {
    return {
      planService: new PlanService(),
      enrollee: {},
      plansArray: [],
      requested_plan: null,
      getInitials,
      providerOptions: [],
      selected_provider: {},
      selectIsLoading: false,
      listOfDependants: [],
    };
  },
  created() {
    this.fetchEnrollee(this.$route.params.id);
    this.fetchHmoPlans();
    this.searchProviders();
  },

  computed: {
    enrolleeInitials() {
      return this.getInitials(`${this.enrollee.firstname} ${this.enrollee.lastname}`)
    }
  },

  methods: {
    submitProviderChangeRequest() {
      this.$swal({
        title: "Are you sure?",
        text: "This action cannot be reversed",
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then((result) => {
        if (!result.value) return false;
        let loader = this.$loading.show();

        Axios.post(`/enrollees/request-provider-change`, {
          provider_id: this.selected_provider.id,
          enrollee_id: this.enrollee.id,
        })
          .then((res) => {
            SuccessToast.fire({
              title: `<span style="color:#fff">${res.data.message}</span>`,
            });
            this.provider = {};
          })
          .catch((err) => {
            ErrorToast.fire({
              title: `<span style="color:#fff">${err}</span>`,
            });
          })
          .finally(() => loader.hide());
      });
    },

    searchProviders(search) {
      this.selectIsLoading = true;
        Axios.get(`/providers`, {
          params: {
            search: search || null,
          },
        })
          .then((res) => {
            this.providerOptions = res.data;
          })
          .catch((err) => showError("Error", err))
          .finally(() => {
            this.selectIsLoading = false;
          });
    },

    fetchEnrollee(id) {
      let loader = this.$loading.show();
      Axios.get(`enrollees/${id}`)
        .then((response) => {
          this.enrollee = response.data;
          this.enrollee.birthdate = moment(
            new Date(this.enrollee.birthdate)
          ).format("YYYY-MM-DD");
          this.enrollee.type = this.enrollee.parent_id ? "D" : "P";
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => loader.hide());
    },

    fetchHmoPlans() {
      this.planService.getPlans()
        .then(response => {
          this.plansArray = response.data.data
        })
        .catch(error => {
          console.error(error)
        })
    },

    requestPlanChange({ id }) {
      this.$swal({
        title: "Are you sure?",
        text: "This action cannot be reversed",
        showCancelButton: true,
        confirmButtonText: "Yes",
        icon: "warning",
      }).then((result) => {
        if (!result.value) return false;
        let loader = this.$loading.show();
        Axios.post(`enrollees/${id}/plan-change-request`, {
          plan_id: this.enrollee.hmo_plan_id,
          hmo_id: 1,
        })
          .then((res) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "success",
              title: "Plan Change Requested",
              text: "You will be notified when approved",
            });
          })
          .catch((err) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "error",
              title: "Error!",
              text: err.message,
            });
          })
          .finally(() => loader.hide());
      });
    },

    requestEnrolleeTermination({ id }) {
      this.$swal({
        title: "Enrollee Termination",
        text: "Are you sure you want to terminate this enrollee?",
        showCancelButton: true,
        confirmButtonText: "Terminate",
        icon: "warning",
      }).then((result) => {
        if (!result.value) return false;
        let loader = this.$loading.show();

        // POINT TO TERMINATION ENDPOINT
        Axios.post(`enrollees/${id}/termination-request`)
          .then((res) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "success",
              title: "Termination Request Sent",
              text: "You will be notified when approved",
            });
          })
          .catch((err) => {
            this.$swal({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              icon: "error",
              title: "Error",
              text: err.message,
            });
          })
          .finally(() => loader.hide());
      });
    },

    formatDate: function (date) {
      return moment(new Date(date)).format("YYYY-MM-DD");
    },

    goBack() {
      this.$router.go(-1)
    }
  },
};
</script>

<style scoped>
.delete-button {
  background-color: #ff4b5c;
  @apply inline-flex justify-center py-2 px-4 border border-transparent text-sm rounded-md text-white;
}

.change-button {
  @apply inline-flex justify-center py-2 px-4 border border-transparent text-sm rounded-md self-end text-white bg-primary;
}

.input-design {
  @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}

input:disabled {
  background: #e5e6e6;
}

.mute {
  @apply capitalize text-xs text-gray-400;
}

.e-details {
  @apply text-sm;
}

button:disabled {
  opacity: 80%;
}

.multiselect__input::before,
.multiselect__input::after {
  --tw-ring-color: none !important;
}
</style>