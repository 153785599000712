<template>
    <div >
        <div v-show="showModal"
             id="modal"
             class="py-12 bg-gray-400 bg-opacity-25 z-10 absolute top-0 right-0 bottom-0 left-0">
            <div class="flex justify-center items-center" role="alert">
                <div class="shadow rounded-lg bg-white slide-down-in">
                    <div class="px-4 py-6 flex justify-between p-x-4 py-4 items-center">
                        <div class="text-header-color font-semibold">Enrollee Details</div>
                        <div><span class="cursor-pointer" @click="modalHandler()"><em class="fas fa-times"></em></span>
                        </div>
                    </div>
                    <div class="border-t border-gray-100"/>
                    <form>
                        <div class="overflow-hidden">
                            <div class="px-4 py-5">
                                <div class="my-3">
                                    <image-uploader :data="{ photo: dependant.base64ImageString }"
                                        @imageUpload="imageChange" initials="Add Photo"/>
                                </div>

                                <div class="grid grid-flow-row grid-cols-3 grid-rows-3 gap-6">
                                    <div class="">
                                        <label class="block text-sm font-medium text-gray-700"
                                               for="lastname">Surname</label>
                                        <input id="lastname" v-model="dependant.lastname"
                                               class="input-design"
                                               type="text"/>
                                      <small class="text-red-600"
                                             v-for="(error, index) in errorsArray['dependant.lastname']"
                                             :key="index">
                                        {{ error }}
                                      </small>
                                    </div>

                                    <div class="">
                                        <label class="block text-sm font-medium text-gray-700" for="first-name">First
                                            name</label>
                                        <input id="first-name" v-model="dependant.firstname"
                                               class="input-design"
                                               type="text"/>
                                      <small class="text-red-600"
                                             v-for="(error, index) in errorsArray['dependant.firstname']"
                                             :key="index">
                                        {{ error }}
                                      </small>
                                    </div>

                                    <div class="">
                                        <label class="block text-sm font-medium text-gray-700" for="last-name">Other
                                            name</label>
                                        <input id="last-name" v-model="dependant.middle_name"
                                               class="input-design"
                                               type="text"/>
                                      <small class="text-red-600"
                                             v-for="(error, index) in errorsArray['dependant.middle_name']"
                                             :key="index">
                                        {{ error }}
                                      </small>
                                    </div>

                                    <div class="">
                                        <label class="block text-sm font-medium text-gray-700" for="relationship">Relationship</label>
                                        <select id="relationship" v-model="dependant.parent_relationship"
                                                class="input-design"
                                        >
                                            <option value=""></option>
                                            <option value="spouse">Spouse</option>
                                            <option value="child">Child</option>
                                        </select>
<!--                                      <small class="text-red-600"-->
<!--                                             v-for="(error, index) in errorsArray['dependant.parent_relationship']"-->
<!--                                             :key="index">-->
<!--                                        {{ error }}-->
<!--                                      </small>-->
                                    </div>

                                    <div class="">
                                        <label class="block text-sm font-medium text-gray-700"
                                               for="gender">Gender</label>
                                        <select id="gender" v-model="dependant.sex"
                                                class="input-design"
                                        >
                                            <option value=""></option>
                                            <option value="M">Male</option>
                                            <option value="F">Female</option>
                                            <option>Prefer not to identify</option>
                                        </select>
                                      <small class="text-red-600"
                                             v-for="(error, index) in errorsArray['dependant.sex']"
                                             :key="index">
                                        {{ error }}
                                      </small>
                                    </div>

                                    <div class="">
                                        <label class="block text-sm font-medium text-gray-700" for="dob">Date of
                                            birth</label>
                                        <input id="dob"
                                               v-model="dependant.birthdate"
                                               class="input-design"
                                               type="date" :max="today" />
                                      <small class="text-red-600"
                                             v-for="(error, index) in errorsArray['dependant.birthdate']"
                                             :key="index">
                                        {{ error }}
                                      </small>
                                    </div>

                                    <div class="">
                                        <label class="block text-sm font-medium text-gray-700" for="phone-number">Phone
                                            Number</label>
                                        <input id="phone-number"
                                               v-model="dependant.phone"
                                               class="input-design"
                                               type="text"/>
                                      <small class="text-red-600"
                                             v-for="(error, index) in errorsArray['dependant.phone']"
                                             :key="index">
                                        {{ error }}
                                      </small>
                                    </div>

                                    <div class="">
                                        <label class="block text-sm font-medium text-gray-700" for="email">Email</label>
                                        <input id="email"
                                               v-model="dependant.email"
                                               class="input-design"
                                               type="email"/>
                                      <small class="text-red-600"
                                             v-for="(error, index) in errorsArray['dependant.email']"
                                             :key="index">
                                        {{ error }}
                                      </small>
                                    </div>

                                </div>
                            </div>
                            <div class="flex items-center justify-end px-4 py-5 space-x-4">
                                <button class="dismiss-button" @click="modalHandler()">Dismiss</button>
                                <button class="add-button" @click.prevent="addDependant">Add Dependant</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="button" class="w-full flex justify-end">
            <button
                class="focus:outline-none text-primary rounded text-white text-sm font-bold space-x-2"
                @click="modalHandler(true)">
                <em class="fas fa-plus"></em><span>Add Dependant</span>
            </button>
        </div>
    </div>
</template>

<script>
import {Axios} from "@/shared/axios";
import {ErrorToast, SuccessToast} from "@/shared/mixins/Toast";
import ImageUploader from "@/shared/ui/image-uploader.vue";
import Swal from "sweetalert2";

export default {
    name: "dependant",
    props: {
        enrollee: Object
    },
    components: { ImageUploader },
    data() {
        return {
            principal: this.enrollee,
            dependant: {},
            showModal: false,
            today: new Date().toISOString().substr(0, 10),
            errorsArray: []
        }
    },
    created() {
        this.dependant = {
            hmo_id: this.enrollee.hmo_id,
            hmo_plan_id: this.enrollee.hmo_plan_id,
            hmo_client_id: this.enrollee.hmo_client_id,
            parent_id: this.enrollee.id,
            parent_relationship: this.enrollee.parent_relationship,
            status: "PP"
        }
    },
    methods: {
        imageChange(value) {
            if(value){
                const myImage = new Image();
                myImage.src = value;
                const _this = this;
                myImage.onload = function() {
                    if(this.width > 700 && this.height > 700){
                        Swal.fire({
                            icon: "error",
                            title: "Image dimension too large",
                            text: "Images resolution should be within 700 * 700px",
                        })
                    }else {
                        _this.dependant.base64ImageString = value
                    }
                };
            }else {
                this.dependant.base64ImageString = null
            }
        },
        modalHandler(val) {
            let modal = document.getElementById("modal");
            if (val) {
                this.fadeIn(modal);
            } else {
                this.fadeOut(modal);
            }
        },
        fadeOut(el) {
            el.style.opacity = 1;
            this.showModal = false;
            (function fade() {
                if ((el.style.opacity -= 0.1) < 0) {
                    el.style.display = "none";
                } else {
                    requestAnimationFrame(fade);
                }
            })();
        },
        fadeIn(el, display) {
            el.style.opacity = 0;
            el.style.display = display || "flex";
            el.style.justifyContent = 'center';
            el.style.alignItems = 'center';
            this.showModal = false;
            (function fade() {
                let val = parseFloat(el.style.opacity);
                if (!((val += 0.2) > 1)) {
                    el.style.opacity = val;
                    requestAnimationFrame(fade);
                }
            })();
        },

        addDependant: function () {
            this.$swal({
                title: 'Are you sure?',
                text: 'This action cannot be reversed',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                icon: 'warning'
            })
                .then(result => {
                    if (!result.value) return false
                    let loader = this.$loading.show();

                    Axios.post(`enrollees/add-dependant`, {
                        dependant: this.dependant
                    })
                        .then(res => {
                            this.$swal({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 4000,
                                icon: 'success',
                                title: 'Addition Requested',
                                text: 'You will be notified when approved'
                            });

                            this.modalHandler();
                        })
                        .catch((err) => {
                          this.errorsArray = err.response.data.errors

                            this.$swal({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 4000,
                                icon: 'error',
                                title: 'Error!',
                                text: err.message,
                            });
                        })
                        .finally(() => loader.hide());
                });
        },
    },
};
</script>

<style scoped>
.input-design {
    @apply mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md;
}

.dismiss-button {
    @apply py-3 px-4 text-gray-600 leading-3 focus:outline-none hover:opacity-90 text-sm font-semibold border-gray-600 rounded border;
}

.add-button {
    @apply inline-flex justify-center py-2 px-4 border border-transparent text-sm rounded-md self-end text-white bg-primary
}
</style>
